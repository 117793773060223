<template>
  <div class="about" id="kikebio">
    <div class="hero">
      <section class="hero_content container">
        <div class="w-md-50">
          <h6 class="hero_text_bold text-orange font-weight-bold my-3">
            FEATURES TO HELP YOU BETTER UNDERSTAND KIKE
          </h6>
          <h2 class="hero_text_bold text-white font-weight-bold my-3">
            What is KikeBio
          </h2>
          <p class="text-white my-4 small" style="color: #f8f8f8">
            The KIKE Green Cook stove and biofuel gel is a low <br />
            cost clean, safe energy and high-performing stove <br />
            for cooking. It provides a significant health benefit to <br />
            families otherwise dependent on dirty <br />
            - solid fuels – kerosene, wood, dung, crop residues, charcoal, even
            <br />
            coal – by eliminating dangerous smokes and gases <br />
            from the home.
          </p>
          <a href="https://owatts.io/@88794212245" target="_blank">
          <button class="btn hero_button text-white small px-5 py-2">Buy Now</button>
        </a>
        </div>
      </section>
    </div>

    <section class="text-white bio_process">
      <div class="container">
        <h5 class="hero_text_bold text-white font-weight-bold my-0">
          Some of the things you <br />
          need to know about KikeBio
        </h5>

        <h5 class="small mt-5 fw-bold">Easily use</h5>
        <!-- <p class="small">
          Track your gas usage from anywhere in the <br />
          world at anytime directly from your mobile App
        </p> -->
        <h5 class="small fw-bold my-5">Process</h5>

        <div class="row process">
          <div class="col-6 text-center">
            <img src="../../../../../public/images/one.png" alt="" />
          </div>
          <div class="col-6 text-center">
            <img
              src="../../../../../public/images/two.png"
              alt=""
              class="img_one"
            />
          </div>
          <div class="col-6 text-center">
            <img src="../../../../../public/images/three.png" alt="" />
          </div>
          <div class="col-6 text-center">
            <img src="../../../../../public/images/four.png" alt="" />
          </div>
          <div class="col-6 text-center five">
            <img src="../../../../../public/images/five.png" alt="" />
          </div>
          <div class="col-6 text-center six">
            <img
              src="../../../../../public/images/six.png"
              alt=""
              class="img_six"
            />
          </div>
          <div class="col-12 text-center">
            <img
              src="../../../../../public/images/seven.png"
              alt=""
              style="width: 45%"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bio_process {
  background: #2e2939;
}
.about .hero {
  /* background: rgb(0, 0, 0); */
  background-image: url(/images/about.png);
  background-size: 100% 100%;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.507);
  background-repeat: no-repeat;
  height: 100%;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  width: 100%;
}

@media (min-width: 1028px) {
  .about .hero {
    height: 700px;
    padding: 0;
  }
}
.fw-bold {
  font-weight: 600;
}
.bio_process {
  padding: 4rem 0;
}
.about .hero_button {
  background-color: var(--bg-orange);
  color: var(--bg-white);
  font-size: small;
  width: 167px;
  height: 45px;
  border-radius: 6px;
}
.about .hero_text_bold {
  font-weight: 600;
}

.five {
  margin-top: 4rem;
}
.six {
  margin-top: 6.8rem;
}
@media (min-width: 766px) {
  .six {
    margin-top: 6rem;
  }
}

.process img {
  width: 90%;
  margin: 1rem auto;
}
.img_six {
  position: relative;
  top: -5rem;
}
.img_one {
  position: relative;
  top: -1.6rem;
}
@media (min-width: 768px) {
  .process img {
    width: 60%;
    /* height: 70%; */
    margin: 2rem auto;
  }
  .img_six {
    position: relative;
    top: -5rem;
  }
  .img_one {
    position: relative;
    top: -2rem;
  }
}
@media (min-width: 968px) {
  .img_one {
    position: relative;
    top: -4rem;
  }
  .five {
    margin-top: 5.3rem;
  }
  .six {
    margin-top: 6rem;
  }
}
</style>
