<template>
  <nav class="biotabs my-4">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button
        class="nav-link active"
        id="nav-home-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-home"
        type="button"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
      >
        Biogel
      </button>
      <button
        class="nav-link"
        id="nav-profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-profile"
        type="button"
        role="tab"
        aria-controls="nav-profile"
        aria-selected="false"
      >
        Kike cook stove
      </button>
      <button
        class="nav-link"
        id="nav-contact-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-contact"
        type="button"
        role="tab"
        aria-controls="nav-contact"
        aria-selected="false"
      >
        Health benefit
      </button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div
      class="tab-pane fade show active"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >
      <div class="d-md-flex justify-content-between">
        <div>
          <ul class="my-4">
            <li>Heats up very quickly with high heat output.</li>
            <li>Non Spill, non explosive, no flare, and non toxic</li>
            <li>Highly safe and economical.</li>
            <li>
              If stove falls over accidentally the flames are contained in one
              place.
            </li>
            <li>1 litre burns continuously for approx. 4 hours and more.</li>
            <li>If ingested, effects are not fatal but just unpleasant</li>
            <li>No Smoke or smell.</li>
            <li>
              When water comes into contact with gel whilst cooking, it does not
              splatter.
            </li>

            <li>No black flame stains on cooking pots</li>
          </ul>
        </div>
        <div class="align-self-center" style="position: relative">
          <img
            src="../../../../../public/images/kikebio.png"
            alt=""
            class="img_eco my-5 my-md-0"
          />
          <img
            src="../../../../../public/images/bubbles.png"
            alt=""
            class="img_bubbles"
          />
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
    >
      <div class="d-md-flex justify-content-between">
        <div>
          <ul class="my-4">
            <li>High quality, sturdy and durable 1 and 2 plate stove</li>
            <li>Lightweight and easy to travel with</li>
            <li>Easy and safe to operate</li>
            <li>Safer than kerosene stoves, with lower centre of gravity</li>
            <li>
              Non-liquid, so it will not spread like kerosene in case of
              spillage
            </li>
            <li>Comes with simmer plate</li>
            <li>Easy and safe to operate</li>
          </ul>
        </div>
        <div class="align-self-center" style="position: relative">
          <img
            src="../../../../../public/images/stove.png"
            alt=""
            class="img_eco my-5 my-md-0"
          />
          <img
            src="../../../../../public/images/bubbles.png"
            alt=""
            class="img_bubbles"
          />
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="nav-contact"
      role="tabpanel"
      aria-labelledby="nav-contact-tab"
    >
      <div class="d-md-flex justify-content-between">
        <div class="align-self-center">
          <ul class="my-4">
            <li>
              It provides a significant health benefit to families otherwise
              dependent on dirty fuel.
            </li>
            <li>
              Eliminating dangerous smoke and gases from the home. Millions of
              <br />
              families around the world still die yearly, mostly women and
              <br />
              children, as a result of cooking smoke and other harmful gases
            </li>
          </ul>
        </div>
        <div class="align-self-center" style="position: relative">
          <img
            src="../../../../../public/images/heart.png"
            alt=""
            class="img_eco my-5 my-md-0"
          />
          <img
            src="../../../../../public/images/bubbles.png"
            alt=""
            class="img_bubbles"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.biotabs .nav-tabs .nav-link {
  color: #9d9a96;
  border: 1px solid #9d9a96;
  border-radius: 12px;
}
.biotabs .nav-tabs {
  border: none;
}
.nav-link {
  padding: 0.2rem 1rem;
  margin-left: 0.5rem;
  font-size: small;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: var(--bg-orange);
  border: none;
  color: #fff;
}
li {
  position: relative;
  list-style: none;
  margin: 1rem 0;
  width: 80%;
  font-size: small;
}

li::before {
  position: absolute;
  content: "✓";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: green;
  left: -2rem;
  top: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: small;
  padding: 0.5rem;
}

.img_bubbles {
  width: 30%;
  position: absolute;
  bottom: -3rem;
  right: 1rem;
  z-index: -1;
}
.img_eco {
  width: 100%;
}

@media (min-width: 768px) {
  .eco_title {
    line-height: 3rem;
  }

  .img_bubbles {
    bottom: -4rem;
    right: 5rem;
  }
  .img_eco {
    width: 75%;
  }
}
</style>
