<template>
  <div class="graphs">
    <div class="content container" id="kikego">
      <h3 class="hero_text_bold font-weight-bold my-0">
        Some of the things you <br />
        need to know about KikeGo
      </h3>
      <h5 class="small mt-5 fw-bold">Easily track your gas usage</h5>
      <!-- <p class="small">
        Track your gas usage from anywhere in the <br />
        world at anytime directly from your mobile App
      </p> -->
      <h5 class="small fw-bold my-5">The Process</h5>
      <div class="row gas_img">
        <div class="col-3">
          <img src="../../../../../public/images/gas-one.png" alt="" />
        </div>
        <div class="col-3">
          <img src="../../../../../public/images/gas-two.png" alt="" />
        </div>
        <div class="col-3">
          <img src="../../../../../public/images/gas-three.png" alt="" />
        </div>
        <div class="col-3">
          <img src="../../../../../public/images/gas-four.png" alt="" />
        </div>
      </div>
      <div style="padding-top: 2rem">
        <h3 class="hero_text_bold font-weight-bold my-0">
          Easily view your gas usage <br />
          & leakage
        </h3>

        <p class="small my-4">
          With our simple graph, you can easily identify how <br />
          much you consume at what day and if your gas <br />
          canister is leaking.
        </p>
        <h5 class="small fw-bold my-5">What you see</h5>
        <GasChart />
      </div>
      <div style="padding-top: 7rem" class="">
        <h4 class="hero_text_bold font-weight-bold my-0">
          Easily monitor and turn off your gas <br />
          from anywhere in the world
        </h4>

        <p class="small my-4">
          Access if your gas is on and then turn it off from <br />
          anywhere in the world, we have a sustainable <br />
          sytem that helps you stay safe at all time.
        </p>
        <h5 class="small fw-bold my-5">How it works</h5>
        <img
          src="../../../../../public/images/Switch.png"
          alt=""
          class="switch"
        />
        <div class="mt-5">
          <img
            src="../../../../../public/images/gasrealtime.png"
            alt=""
            style="width: 100%"
          />
        </div>

        <!-- <div class="text-center arrowright_holder">
          <img src="../../../../../public/images/grid.png" alt="" />
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <img
              v-for="x in arrowdown"
              src="../../../../../public/images/arrowdown.png"
              alt=""
              class="arrowdown"
            />
          </div>
        </div> -->
        <!-- <div
          class="d-flex justify-content-between align-items-center realtime"
          style="gap: 1rem"
        >
          <div class="">
            <div class="d-flex align-items-center">
              <img src="../../../../../public/images/battery.png" alt="" />
          
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <img
                v-for="(x, index) in itemsShow"
                :class="x"
                src="../../../../../public/images/arrowleft.png"
                alt=""
                style="width: 4%; margin: 0px 0.4rem"
              />

              <img src="../../../../../public/images/inv.png" alt="" />
              <img
                v-for="x in itemsShow"
                src="../../../../../public/images/arrowright.png"
                alt=""
                style="width: 4%; margin: 0px 0.4rem"
              />
            </div>
          </div>
          <div>
            <img src="../../../../../public/images/gas-one.png" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import GasChart from "./GasChart.vue";
const itemsShow = ref(15);
const arrowdown = ref(10);

const innerW = ref(window.innerWidth);

onMounted(() => {
  if (innerW.value > 1200) {
    itemsShow.value = 19;
    arrowdown.value = 8;
    // showNavigation.value = false;
  } else if (innerW.value < 768) {
    itemsShow.value = 4;
    arrowdown.value = 5;
  } else {
    itemsShow.value = 5;
    arrowdown.value = 5;
    // showNavigation.value = true;
  }
});
</script>

<style scoped>
.graphs {
  background: #fef9f4;
  color: #2c2926;
  padding: 4rem 0;
}
.graphs .hero_text_bold {
  font-weight: 600;
}
.gas_img {
  padding-bottom: 6rem;
}
.gas_img img {
  width: 100%;
}

.realtime {
  margin-top: 2rem;
}
.realtime img {
  width: 100%;
}
.arrowdown {
  margin: 0.6rem 0;
}
@media (max-width: 768px) {
  .realtime img {
    width: 60px;
  }
}
@media (min-width: 768px) {
  .arrowright_holder {
    position: relative;
    right: 4rem;
  }
}
@media (min-width: 998px) {
  .realtime {
    margin-top: 0rem;
  }
  .gas_img img {
    width: 80%;
  }
  .arrowright_holder {
    position: relative;
    right: 4rem;
  }
  .arrowdown {
    margin: 0.4rem 0;
  }
}
@media (min-width: 1399px) {
  .arrowright_holder {
    position: relative;
    right: 4rem;
  }
}

.switch {
  width: 110px;
  cursor: pointer;
}
</style>
