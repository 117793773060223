<template>
  <div class="d-flex justify-content-between my-5">
    <div>
      <img src="../../../../../public/images/logo2.png" alt="" />
    </div>
    <div>
      <a href="">
        <a href="https://twitter.com/owattsinc">
          <img src="../../../../../public/images/twitter.png" alt="" />
        </a>
      </a>
      <a href="https://web.facebook.com/owattsinc?_rdc=1&_rdr">
        <img src="../../../../../public/images/fb.png" alt="" />
      </a>
      <a href="https://wa.me/<number>">
        <img src="../../../../../public/images/whatsapp.png" alt="" />
      </a>
      <a href="https://www.instagram.com/owattsinc/">
        <img src="../../../../../public/images/ig.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  margin: 0px 0.2rem;
}
a img {
  width: 30px;
}
</style>
