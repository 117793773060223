<template>
  <div class="d-md-flex justify-content-between my-5 py-5" id="features">
    <div>
      <h6 class="text-blue font-weight-bold small">
        FEATURES TO HELP YOU BETTER UNDERSTAND KIKE
      </h6>
      <h2 class="eco_title my-3">Features of KikeGo</h2>
      <ul class="my-4">
        <li>Promotes a more convenient cooking lifestyle.</li>
        <li>
          Bundled with built-in alarm, alerts and recipes with self-command
          timer for different meals.
        </li>
        <li>Comes with a human scale feature.</li>
        <li>Designed with a 2 second auto turn off gas discharge.</li>
        <li>Additional LPG cylinder at no extra cost.</li>
        <li>Up to 20% cost efficient than conventional methods.</li>
        <li>
          Access your Kike gas remotely (turn on or off) via the Owatts mobile
          app.
        </li>
      </ul>
    </div>
    <div class="align-self-center" style="position: relative">
      <img
        src="../../../../../public/images/mobile1.png"
        alt=""
        class="img_eco my-5 my-md-0"
      />
      <img
        src="../../../../../public/images/bubbles.png"
        alt=""
        class="img_bubbles"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.eco_title {
  color: #2c2926;
  font-weight: 600;
  line-height: 2rem;
  font-weight: 700 !important;
  letter-spacing: 0.1rem;
}

li {
  position: relative;
  list-style: none;
  margin: 1rem 0;
  width: 80%;
  font-size: small;
}

li::before {
  position: absolute;
  content: "✓";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: green;
  left: -2rem;
  top: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: small;
  padding: 0.5rem;
}

.img_bubbles {
  width: 30%;
  position: absolute;
  bottom: -3rem;
  right: 1rem;
  z-index: -1;
  display: none;
}
.img_eco {
  width: 100%;
}

@media (min-width: 768px) {
  .eco_title {
    line-height: 3rem;
  }

  .img_bubbles {
    bottom: -4rem;
    right: 5rem;
  }
  .img_eco {
    width: 80%;
  }
}
</style>
