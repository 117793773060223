<template>
  <nav class="navbar navbar-expand-lg navbar-light pt-md-5">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" style="width: 10%">
        <img
          src="../../../../../public/images/KikeLogo.png"
          alt=""
          style="width: 100%"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"
          ><CIcon icon="cilList" size="sm"
        /></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a
              class="nav-link active"
              aria-current="page"
              href="javascript:void(0)"
              @click="scrollToElement('#home')"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="scrollToElement('#kikego')"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              >KikeGo</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="scrollToElement('#kikebenefits')"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              >Benefits of Kike Go
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="scrollToElement('#features')"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              >Features
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="scrollToElement('#kikebio')"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              >KikeBio
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="scrollToElement('#kikebenefitsBio')"
              >Benefits of Kike Bio
            </a>
          </li>
          <li class="nav-item">
            <button
              class="navbar-toggler text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon me-3"
                ><CIcon icon="cilXCircle" size="sm" /></span
              >CLOSE
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    scrollToElement(id) {
      console.log(id);
      // takes input id with hash
      // eg. #cafe-menu
      const el = document.querySelector(id);
      console.log(el);
      el &
        el.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: #fff !important;
  font-size: small;
}
.nav-item {
  margin: 0px 1rem;
}
.navbar-toggler {
  border: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: none !important;
  font-size: small;
}
.navbar-light .navbar-toggler-icon svg {
  color: white;
}
@media (max-width: 992px) {
  .navbar-collapse.collapsing {
    display: none;
  }
  .navbar-collapse.show {
    background: #000;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99;
    display: flex;
  }
  .navbar-nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }
}
.navbar-collapse.show.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
