<template>
  <Hero />
  <div class="container">
    <HeroCards />
    <EcoBenefits />
  </div>
  <div>
    <KikeGraphs />
  </div>
  <div class="container">
    <KikeFeatures />
  </div>

  <div>
    <AboutKikeBio />
  </div>
  <div class="container">
    <KikeBio />
    <Footer />
  </div>
</template>

<script>
import Hero from "../components/Hero.vue";
import HeroCards from "../components/HeroCards.vue";
import EcoBenefits from "../components/EcoBenefits.vue";
import KikeFeatures from "../components/KikeFeatures.vue";
import KikeGraphs from "../components/KikeGraphs.vue";
import KikeBio from "../components/KikeBio.vue";
import AboutKikeBio from "../components/AboutKikeBio.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Hero,
    HeroCards,
    EcoBenefits,
    KikeFeatures,
    KikeGraphs,
    KikeBio,
    Footer,
    AboutKikeBio,
  },
};
</script>

<style></style>
