import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueApexCharts from "vue3-apexcharts";
import { CIcon } from "@coreui/icons-vue";
import {
  cilList,
  cilXCircle,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
} from "@coreui/icons";
const icons = {
  cilList,
  cilXCircle,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
};
createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .provide("icons", icons)
  .component("CIcon", CIcon)
  .mount("#app");
