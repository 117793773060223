<template>
  <div class="d-md-flx justify-content-between my-5 py-5" id="kikebenefitsBio">
    <div>
      <h6 class="text-blue font-weight-bold small">
        STAND A CHANCE TO BE PART OF THE GREEN REVOLUTION
      </h6>
      <h2 class="eco_title my-3">
        Ecological benefits <br />
        of using KikeBio
      </h2>
      <div class="my-4">
        <BioTabs />
      </div>
    </div>
    <!-- <div class="align-self-center" style="position: relative">
      <img
        src="../../../../../public/images/kikebio.png"
        alt=""
        class="img_eco my-5 my-md-0"
      />
      <img
        src="../../../../../public/images/bubbles.png"
        alt=""
        class="img_bubbles"
      />
    </div> -->
  </div>
</template>

<script>
import BioTabs from "./BioTabs.vue";
export default {
  components: {
    BioTabs,
  },
};
</script>

<style scoped>
.eco_title {
  color: #2c2926;
  font-weight: 600;
  line-height: 2rem;
  font-weight: 700 !important;
  letter-spacing: 0.1rem;
}

li {
  position: relative;
  list-style: none;
  margin: 1rem 0;
  width: 80%;
  font-size: small;
}

li::before {
  position: absolute;
  content: "✓";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: green;
  left: -2rem;
  top: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: small;
  padding: 0.5rem;
}

.img_bubbles {
  width: 30%;
  position: absolute;
  bottom: -3rem;
  right: 1rem;
  z-index: -1;
}
.img_eco {
  width: 100%;
}

@media (min-width: 768px) {
  .eco_title {
    line-height: 3rem;
  }

  .img_bubbles {
    bottom: -4rem;
    right: 5rem;
  }
  .img_eco {
    width: 80%;
  }
}
</style>
