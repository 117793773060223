<template>
  <router-view />
</template>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(../src/fonts/Manrope/Manrope-VariableFont_wght.ttf) format("truetype");
}
body {
  font-family: "Manrope", "ui-sans-serif" !important;
}
</style>
