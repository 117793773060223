<template>
  <Carousel :items-to-show="itemsShow" :wrap-around="true" class="my-5">
    <Slide v-for="slide in carouselItem.length" :key="slide">
      <div class="mb-3 mx-2 mx-md-0">
        <div class="card text-start">
          <img
            :src="
              require(`../../../../../public/images/${
                carouselItem[slide - 1].img
              }`)
            "
            alt=""
            class="card_img"
          />
          <h6 class="my-3" v-if="carouselItem">
            {{ carouselItem[slide - 1].title }}
          </h6>
          <p class="py-0 mb-2">
            {{ carouselItem[slide - 1].text }}
          </p>
        </div>
      </div>
    </Slide>
  </Carousel>
  <!-- <div class="row my-5">
    <div class="col-md-4 mb-3">
      <div class="card">
        <img
          src="../../../../../public/images/submit.png"
          alt=""
          style="width: 60px"
        />
        <h5 class="my-4">SMART</h5>
        <p>
          Enjoy the world of smart and efficient technology for your everyday
          use.
        </p>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="card">
        <img
          src="../../../../../public/images/submit.png"
          alt=""
          style="width: 60px"
        />
        <h5 class="my-4">SAFE</h5>
        <p>It is safe to your health and your environement.</p>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="card">
        <img
          src="../../../../../public/images/submit.png"
          alt=""
          style="width: 60px"
        />
        <h5 class="my-4">SECURE</h5>
        <p>100% secure for personal and business use.</p>
      </div>
    </div>
  </div> -->
</template>

<script setup>
import { onMounted, ref } from "vue";
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
const innerW = ref(window.innerWidth);

const itemsShow = ref(5);

const carouselItem = ref([
  {
    img: "safe.png",
    title: "SAFE",
    text: "It is safe to your health and your environment.",
  },
  {
    img: "secure.png",
    title: "SECURE",
    text: "100% secure for personal and business use.",
  },
  {
    img: "safe.png",
    title: "SAFE",
    text: "It is safe to your health and your environement.",
  },
  {
    img: "submit.png",
    title: "SUBMIT",
    text: "Enjoy the world of smart and efficient technology for your everyday use.",
  },
]);
onMounted(() => {
  if (innerW.value < 768) {
    itemsShow.value = 1.4;
    // showNavigation.value = false;
  } else {
    itemsShow.value = 3;
    // showNavigation.value = true;
  }
});
</script>

<style scoped>
.card {
  padding: 1rem;
  background: #2c2926;
  border-radius: 10px;
  color: #fff;
  height: 180px;
  width: 100%;
}
.card p {
  color: #d7d4d1;
}
.card_img {
  width: 35px;
  height: 35px;
}

@media (min-width: 1028px) {
  .card {
    width: 300px;
  }
}
</style>
