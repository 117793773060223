<template>
  <div class="hero relative" id="hero">
    <div class="container">
      <NavbarComponent />

      <section class="hero_content">
        <div class="w-md-50">
          <h3 class="hero_text_orange mb-4">
            You get full oversight and control of your gas & STOVE
          </h3>
          <h2 class="hero_text_bold">Kike is a smart</h2>
          <h2 class="hero_text_bold">metering solution</h2>
          <p class="hero_text_muted mt-4">
            Which provides clean, safer and uninterrupted <br />
            cooking fuel for homes and commecial <br />
            businesses.
           
          </p>
          <a href="https://owatts.io/@88794212245" target="_blank">
          <button class="btn hero_button px-5 py-2">Buy Now</button>
        </a>
        </div>
      </section>
    </div>
    <div class="hero_blur"></div>
  </div>
</template>

<script>
import NavbarComponent from "./NavbarComponent.vue";
export default {
  components: { NavbarComponent },
};
</script>

<style scoped>
.hero {
  /* background: rgb(0, 0, 0); */
  background-image: url(/hero.png);
  background-size: cover;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.61);
  background-repeat: no-repeat;
  height: max-content;
  width: 100%;
}

.w-md-50 {
  width: 100%;
}

.hero_content {
  color: var(--text-white);
  height: max-content;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  text-align: center;
}

@media (min-width: 1028px) {
  .w-md-50 {
    width: 50%;
  }
  .hero {
    background-size: 100% 100%;
    height: 750px;
  }
  .hero_content {
    color: var(--text-white);
    height: 80vh;
    display: flex;
    align-items: center;
    text-align: left;
  }
}
.hero_text_orange {
  color: var(--text-orange);
  text-transform: uppercase;
  font-size: medium;
  font-weight: bold;
}

.hero_text_bold {
  font-weight: 700;
  font-size: 35px;
}
@media (min-width: 768px) {
  .hero_text_bold {
    font-weight: 700;
    font-size: 45px;
  }
}
.hero_text_muted {
  color: #f8f8f8 !important;
  font-size: medium;
  font-weight: large;
}

.hero_button {
  background-color: var(--bg-orange);
  color: var(--bg-white);
  width: 187px;
  height: 45px;
  border-radius: 6px;
  font-size: small;
}
</style>
