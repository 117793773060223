<template>
  <div id="chart">
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data: function () {
    return {
      series: [
        {
          name: "Usage",
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
          color: "#FF7E00",
        },
        {
          name: "Leakage",
          data: [
            75, 72, 68, 64, 63, 60, 59, 58, 56, 55, 55, 51, 49, 48, 48, 44, 43,
            42, 41, 38, 36, 33, 32, 20, 28, 22, 18, 15, 14, 10, 9,
          ],
          color: "green",
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 2, 3],
          curve: "straight",
          dashArray: [0, 0, 0],
        },
        legend: {
          position: "top",
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              ""
            );
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
          ],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + " (mins)";
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val + " (kg)";
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          ],
        },
        grid: {
          //   borderColor: "#f1f1f1",
        },
      },
    };
  },
};
</script>

<style>
.apexcharts-menu-icon {
  display: none;
}
.apexcharts-legend {
  left: auto !important;
}
</style>
